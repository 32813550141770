import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Payload } from './dc-alert-model';

@Injectable({
  providedIn: 'root',
})
export class DcAlertService {
  constructor() {}

  private alertTypeSource = new Subject();

  alertType$ = this.alertTypeSource.asObservable();

  showAlert(payload: Payload) {
    this.alertTypeSource.next(payload);
  }
}
