import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dc-header',
  templateUrl: './dc-header.component.html',
  styleUrls: ['./dc-header.component.scss'],
})
export class DcHeaderComponent implements OnInit, AfterViewInit {
  @Input() backLink: string;
  @Input() title: string;
  back;

  constructor(private router: Router) {}

  ngOnInit(): void {
    // console.log(this.backLink);
    this.back = this.backLink;
  }

  ngAfterViewInit() {
    this.back = this.backLink;
    // console.log('---ngAfterViewInit() Demo---');
  }

  navLink(link) {
    this.router.navigate([link]);
  }
}
